import apiClient from "./apiClient";
//import constant from './constants';

export default {
  getPatientSearchResults(data) {
    return apiClient.post("list/patient-search-results", data);
  },
  getPatientById(data) {
    return apiClient.post("list/PatientGetById", data);
  },
  deletePatient(data) {
    return apiClient.post("masters/patientDelete", data);
  },
  getPatientComments(data) {
    return apiClient.post("list/patientcommentsGetById", data);
  },
  getEnrolmentList(data) {
    return apiClient.post("happyfox/enrolment-list", data);
  },
  getEnrolmentRawData(data) {
    return apiClient.post("happyfox/get-enrollment", data);
  },
  getSaveEnrollmentHappyFox(data) {
    return apiClient.post("happyfox/save", data);
  },
  enrollmentComplete(data) {
    return apiClient.post("happyfox/complete-enrollment", data);
  },
  saveDemographics(data) {
    return apiClient.post("masters/savedemographicsdetails", data);
  },
  saveComments(data) {
    return apiClient.post("masters/AddUpdateComment", data);
  },
  deleteComment(data) {
    return apiClient.post("masters/PatientCommentsDelete", data);
  },
  getAuditTrail(data) {
    return apiClient.post("list/getPatientLetterAuditList", data);
  },
  saveOpt_In_Opt_Out(data) {
    return apiClient.post("masters/savepatientopt", data);
  },
  savefavourite(data) {
    return apiClient.post("masters/savefavoritepatient", data);
  },
  //--------------------------------------------------
  //Scheduling
  //--------------------------------------------------
  getpatientGetScheduling(data) {
    return apiClient.post("scheduling/patientGetScheduling", data);
  },
  saveScheduling(data) {
    return apiClient.post("scheduling/PatientSaveScheduling", data);
  },
  getNotificationText(data) {
    return apiClient.post("scheduling/get-notification-text", data);
  },
  sendNotification(data) {
    return apiClient.post("scheduling/notification-send", data);
  },
  deleteSchedule(data) {
    return apiClient.post("scheduling/delete", data);
  },
  //--------------------------------------------------

  //--------------------------------------------------
  //Scheduling
  //--------------------------------------------------
  getPatientGetInsurance(data) {
    return apiClient.post("scheduling/patientGetInsurance", data);
  },
  savePatientSaveInsurance(data) {
    return apiClient.post("scheduling/patientSaveInsurance", data);
  },
  //--------------------------------------------------

  //--------------------------------------------------
  //Device
  //--------------------------------------------------
  getPatientGetDeviceList(data) {
    return apiClient.post("list/patientDevicesList", data);
  },
  savePatientDeviceData(data) {
    return apiClient.post("masters/savePatientDeviceData", data);
  },
  saveDeviceData(data) {
    return apiClient.post("masters/saveDeviceData", data);
  },
  deleteDeviceData(data) {
    return apiClient.post("masters/delete-patient-device", data);
  },
};
