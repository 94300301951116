import store from "@/store/index";
import patientAPI from "@/api/patientAPI.js";
import router from "@/router/index.js";

export default {
  data: () => ({
    selectedItem: null,
    drawer: null,

    fav: true,
    menu: false,
    message: false,
    hints: true,
    // full_name:null,
    // user_role:null,
  }),
  methods: {
    logout(e) {
      e.preventDefault();
      window.location.href = "login";
      //this.$router.go("/")
      //this.$router.push({path:"/login"});
    },

    add_remove_favourite(vrpatient_id) {
      patientAPI
        .savefavourite({
          patient_id: vrpatient_id,
          is_my_patient: "F",
        })
        .then((response) => {
          if (response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          store.dispatch("getFavoritePatientList");
        });
      return false;
    },

    selectPatient(item) {
      //Set the patient Id for other screens/flow
      store.state.patientId = item.patient_id;

      //store.state.redirectURL = "search";
      router.push("select").catch(() => {});
      return false;
    },
  },
  mounted: () => {
    store.dispatch("getFavoritePatientList");
  },
  computed: {
    vrUserFullName() {
      return sessionStorage.getItem("name");
    },
    vrUserRole() {
      return sessionStorage.getItem("type");
    },
    allowAppointmentReport() {
      return store.state.allowAppointmentReport;
    },
    allowUploadDetails() {
      return store.state.allowUploadDetails;
    },
    allowUserManagement() {
      return store.state.allowUserManagement;
    },
    favoritePatientList() {
      return store.state.arrFavoritePatientList;
    },
    role_id() {
      return store.getters.user.role_id;
    },
  },
};
